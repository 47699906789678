<template>
  <div class="filters">
    <p>Show:</p>
    <div class="filters__wrapper">
      <div class="filters__item">
        <base-select
          :options="filterTypes"
          v-model="filters.type"
        ></base-select>
      </div>
      <div class="filters__item">
        <date-range-picker
          ref="picker"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          :showDropdowns="true"
          :autoApply="true"
          v-model="dateRange"
          :linkedCalendars="true"
          :dateFormat="dateFormat"
          @select="selectDate"
        >
        </date-range-picker>
      </div>
    </div>
  </div>
</template>

<script>
let date = new Date();
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
export default {
  props: {
    filterTypes: Array,
  },
  data() {
    return {
      dateRange: { startDate, endDate },
      filters: {
        type: "transactions",
        startDate: new Date(startDate).getTime().toString(),
        endDate: new Date(endDate).getTime().toString(),
      },
    };
  },
  components: { DateRangePicker },
  methods: {
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() > new Date();
      }
      return classes;
    },
    selectDate(date) {
      this.filters.startDate = new Date(date.startDate).getTime().toString();
      this.filters.endDate = new Date(date.endDate).getTime().toString();
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.$emit("filter", this.filters);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  align-items: center;
  @media screen and (max-width: 991px) {
    display: block;
  }
  &__item {
    &:not(:last-child) {
      margin-right: rem(20px);
    }
    ::v-deep {
      .form-group {
        margin-bottom: 0px;
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    .v-select {
      &:not(:last-child) {
        margin-right: rem(12px);
      }
      ::v-deep {
        .v-select-toggle {
          min-width: 120px;
          color: var(--textPrimary);
          font-size: rem(14px);
          font-weight: 700;
          @include flex(center, center);
          padding: rem(10px) rem(28px) rem(10px) rem(23px);
          height: 40px;
          line-height: 21px;
          @media screen and (max-width: 575px) {
            padding: rem(10px);
          }
          @media screen and (max-width: 414px) {
            min-width: 100px;
            width: 100%;
          }
          > div {
            position: relative;
            &:after {
              content: "";
              background: none;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -14px;
              border-top: 8px solid;
              border-right: 5px solid transparent;
              border-left: 5px solid transparent;
              transition: 0.5s ease all;
              border-radius: 28px;
            }
          }
          .arrow-down {
            display: none;
          }
        }
      }
    }
  }
}
</style>
